/**
 * These theme values will override the base theme.
 *
 * We're passing these values to Layout component;
 * The Layout component merges these values with the
 * base theme.
 *
 */

// Importing default theme color object to mutate it
import defaultColors from '@elegantstack/solid-ui-theme/src/colors'

// Using tailwind preset colors
import colors from '@elegantstack/solid-ui-theme/src/color-preset'

export default {
  colors: {
    background: `#FCFCFF`,
    headerBg: `transparent`,
    footerBg: `#072E2E`
  }
}
